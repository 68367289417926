import update from 'immutability-helper';
import { isArray, isEmpty, uniqBy } from 'lodash';
import { runningAdmissionActions } from '../../actions';
import initialState from '../../initialState';

function runningAdmission(state = initialState.runningAdmission, actions) {
  switch (actions.type) {
    case runningAdmissionActions.GET_RUNNING_ADMISSION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case runningAdmissionActions.GET_RUNNING_ADMISSION_SUCCESS:
      return {
        ...state,
        runningAdmissions: actions.data,
        loadError: {},
        loading: false,
      };
    case runningAdmissionActions.GET_RUNNING_ADMISSION_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case runningAdmissionActions.CREATE_SINGLE_RUNNING_ADMISSION_REQUEST:
      return {
        ...state,
        gettingOne: true,
        getOneError: {},
      };
    case runningAdmissionActions.CREATE_SINGLE_RUNNING_ADMISSION_SUCCESS:
      return {
        ...state,
        gettingOne: false,
        runningAdmission: actions.data,
      };
    case runningAdmissionActions.CREATE_SINGLE_RUNNING_ADMISSION_ERROR:
      return {
        ...state,
        gettingOne: false,
        getOneError: actions.error,
      };

    case runningAdmissionActions.GET_SELECTED_RUNNING_ADMISSION_REQUEST:
      return {
        ...state,
        gettingSelectedRunningAdmission: true,
        getRunningAdmissionError: {},
      };

    case runningAdmissionActions.GET_SELECTED_RUNNING_ADMISSION_SUCCESS: {
      const { selectedRunningAdmissions } = state;
      const { data } = actions;

      const findDataIndex = selectedRunningAdmissions.findIndex(
        (selectedRunningAdmission) =>
          selectedRunningAdmission.id === actions.data?.id
      );

      let newState = update(state, {
        selectedRunningAdmissions: {
          $push: [data],
        },
      });
      if (findDataIndex !== -1) {
        newState = update(state, {
          selectedRunningAdmissions: { [findDataIndex]: { $set: data } },
        });
      }

      return {
        ...newState,
        gettingSelectedRunningAdmission: false,
      };
    }

    case runningAdmissionActions.GET_SELECTED_RUNNING_ADMISSION_ERROR:
      return {
        ...state,
        gettingSelectedRunningAdmission: false,
        getRunningAdmissionError: actions.error,
      };

    case runningAdmissionActions.GET_RUNNING_ADMISSION_PROGRAMMES_REQUEST:
      return {
        ...state,
        gettingRunningAdmissionsProgrammes: true,
        runningAdmissionsProgrammesError: {},
      };

    case runningAdmissionActions.GET_RUNNING_ADMISSION_PROGRAMMES_SUCCESS: {
      const { runningAdmissionsProgrammes } = state;
      const { data, runningAdmissionId } = actions;

      const findDataIndex = runningAdmissionsProgrammes.findIndex(
        (context) => context.running_admission_id === runningAdmissionId
      );

      let newState = update(state, {
        runningAdmissionsProgrammes: {
          $push: [
            {
              running_admission_id: runningAdmissionId,
              programmes: data,
            },
          ],
        },
      });
      if (findDataIndex !== -1) {
        newState = update(state, {
          runningAdmissionsProgrammes: {
            [findDataIndex]: {
              $set: {
                running_admission_id: runningAdmissionId,
                programmes: data,
              },
            },
          },
        });
      }

      return {
        ...newState,
        gettingRunningAdmissionsProgrammes: false,
      };
    }

    case runningAdmissionActions.GET_RUNNING_ADMISSION_PROGRAMMES_ERROR:
      return {
        ...state,
        gettingRunningAdmissionsProgrammes: false,
        runningAdmissionsProgrammesError: actions.error,
      };

    case runningAdmissionActions.GET_RUNNING_ADMISSION_APPLICANT_CONTEXT_REQUEST:
      return {
        ...state,
        gettingRunningAdmissionApplicant: true,
        runningAdmissionApplicantError: {},
      };

    case runningAdmissionActions.GET_RUNNING_ADMISSION_APPLICANT_CONTEXT_SUCCESS: {
      const { runningAdmissionApplicants } = state;
      let { currentRunningAdmissionContext } = state;
      const { data } = actions;

      if (!isEmpty(data) && isArray(data)) {
        const { 0: firstContext } = data;
        const findContext = data.find(
          (admissionApplicantData) =>
            admissionApplicantData.form_id ===
            currentRunningAdmissionContext?.form_id
        );
        currentRunningAdmissionContext = findContext || firstContext;
      }

      const newState = update(state, {
        runningAdmissionApplicants: {
          $set: uniqBy([...data, ...runningAdmissionApplicants], 'form_id'),
        },
        currentRunningAdmissionContext: {
          $set: currentRunningAdmissionContext,
        },
      });

      return {
        ...newState,
        gettingRunningAdmissionApplicant: false,
      };
    }

    case runningAdmissionActions.GET_RUNNING_ADMISSION_APPLICANT_CONTEXT_ERROR:
      return {
        ...state,
        gettingRunningAdmissionApplicant: false,
        runningAdmissionApplicantError: actions.error,
      };

    case runningAdmissionActions.SET_SELECTED_RUNNING_ADMISSION:
      return {
        ...state,
        selectedRunningAdmission: actions.payload,
      };

    case runningAdmissionActions.SET_SELECTED_RUNNING_ADMISSION_PROGRAMMES:
      return {
        ...state,
        selectedProgrammes: actions.payload,
      };

    case runningAdmissionActions.SET_CURRENT_RUNNING_ADMISSION_CONTEXT:
      return {
        ...state,
        currentRunningAdmissionContext: actions.payload,
      };

    case runningAdmissionActions.SET_MY_APPLICATION_FORMS:
      return {
        ...state,
        myApplicationForms: actions.payload,
      };

    case runningAdmissionActions.SET_CURRENT_APPLICATION_FORM:
      return {
        ...state,
        currentApplicationForm: actions.payload,
      };

    case runningAdmissionActions.SET_DEFAULT_FORM_ID:
      return {
        ...state,
        defaultFormId: actions.payload,
      };

    case runningAdmissionActions.SUBMIT_APPLICANT_FORM_REQUEST:
      return {
        ...state,
        submittingForm: true,
        submitError: {},
        submittedForm: {},
      };

    case runningAdmissionActions.SUBMIT_APPLICANT_FORM_SUCCESS:
      return {
        ...state,
        submittingForm: false,
        submittedForm: actions.data,
      };

    case runningAdmissionActions.SUBMIT_APPLICANT_FORM_ERROR:
      return {
        ...state,
        submittingForm: false,
        submittedError: actions.error,
      };

    default:
      return state;
  }
}

export default runningAdmission;
