const admission = {
  runningAdmission: {
    loading: false,
    runningAdmissions: [],
    runningAdmission: {},
    loadError: {},
    gettingOne: false,
    selectedRunningAdmission: {},
    selectedProgrammes: [],
    gettingRunningAdmissionsProgrammes: false,
    runningAdmissionsProgrammes: [],
    runningAdmissionsProgrammesError: {},
    gettingRunningAdmissionApplicant: false,
    runningAdmissionApplicants: [],
    runningAdmissionApplicantError: {},
    currentRunningAdmissionContext: {},
    submittingForm: false,
    submitError: {},
    submittedForm: {},
    myApplicationForms: [],

    currentApplicationForm: {},

    defaultFormId: null,

    selectedRunningAdmissions: [],
    gettingSelectedRunningAdmission: false,
    getRunningAdmissionError: {},
  },
  applicationSection: {
    loading: false,
    loadError: {},
    applicationSections: [],

    newFormId: null,

    creating: false,
    createError: {},
    createdSection: {},

    updating: false,
    updateError: {},
    updatedSection: {},

    singleApplicationSections: [],
    singleApplicationSection: {},

    deleting: false,
    deleteError: {},
    deleteSuccess: {},

    gettingUnebResults: false,
    unebResultError: {},
    unebResultSuccess: {},
  },
  myApplicationForm: {
    loading: false,
    myApplicationForms: [],
    loadError: {},
    generatingPaymentReference: false,
    paymentReferenceError: {},
    paymentReferenceSuccess: {},

    gettingApplicationForm: false,
    applicationForms: [],
    applicationFormError: {},

    downloadingForm: false,
    downloadFormId: null,
    downloadFormError: {},

    selectedApplication: {},
    showPreviewModal: false,

    showVisaModal: false,

    downloadingAdmissionLetter: false,
    downloadAdmissionLetterError: {},

    checkingAdmissions: false,
    myAdmissions: [],
    admissionsError: {},
  },
};

export default admission;
